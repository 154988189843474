<template>
    <div  class="global-content">
        <div class="block global-margin-bottom global-shadow">
            <div><i class="fa fa-arrow-right global-title-fa"></i><span class="global-title">请选择您要操作的设备</span><span class="global-remark">（单选）</span></div>
            <van-divider />
            <div class="handle" v-if="devData.length != 0">
                <van-radio-group class="box-container" v-model="result" direction="horizontal" @change="changeDevice">
                    <van-col span="8" v-for="(item,index) in devData" :key="index">
                        <van-radio checked-color="#0957ff" :name="item.devCode">{{item.devName}}</van-radio>
                    </van-col>
                </van-radio-group>
            </div>
            <div v-else class="empty-hint">{{hintText}}</div>
        </div>
        <div class="block global-shadow">
            <div><i class="fa fa-picture-o global-title-fa"></i><span class="global-title">拍照控制</span></div>
            <van-divider />
            <div class="info">
                <van-row>
                    <van-col span="6" v-for="(item,i) in pictureData" :key="item.itemCode" 
                    @click="selectItem(item.itemCode,i)" 
                    :class="item.itemCode === '0' && currentDevIsOpen ? 'active':'only' ">
                        <div><i class="fa fa-camera"></i></div>
                        <div class="text" v-text="item.itemName"></div>
                        <div v-if="item.itemCode === '0'" class="text">{{currentDevIsOpen ? '(开)' : '(关)'}}</div>
                    </van-col>
                </van-row>
            </div>
            <div class="hint">
                <div>1.周期拍照指令下发成功后，到状态回显有两三分钟延迟；</div>
                <div>2.实时拍照成功后，可前往<span @click="goMedia">媒体列表</span>查看最新图片。</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    // name:'picture',
    data(){
        return{
            devData:[],
            pictureData:[],
            // configItemIndex:0,
            result: '',
            picState:'',
            hintText:'加载中...',
            isAisle:true,
            projectCode:null,
            currentDevIsOpen:false,
        }
    },
    mounted(){
        // 获取所有设备信息
        this.projectCode = window.sessionStorage.getItem('projectCode');
        this.$api.ITGS.getAllDevInfo({
            projectCode:this.projectCode,
            controlDevMediaPeriod:1
        }).then((data)=>{
            if(Array.isArray(data)){
                data.length == 0 ?  this.hintText='具备功能的设备列表为空' : this.devData = data;        
            }
        })
        // 获取拍照控制选项
        this.$api.ITGS.getControlDict({typeCode:'control_photo'}).then((data)=>{
            this.pictureData = data;
             if(this.$route.query.devCode){
                this.result = this.$route.query.devCode;
                this.$api.ITGS.getAppBeat({devCode:this.$route.query.devCode}).then((data)=>{
                    this.picState = data.picState;
                    // this.configItemIndex = this.pictureData.findIndex((i)=> this.picState == i.itemCode);
                })
            }
        })
       
    },
    methods:{
        changeDevice(code){
            this.currentDevIsOpen = false
            this.$api.ITGS.appBeat(code).then((data)=>{
                if(data){
                    this.currentDevIsOpen = data.picState === 0 ? true : false
                }else{
                    this.currentDevIsOpen = false
                }
            })
        },
        // 提交单个设备控制
        selectItem(code,i){
            if(!this.result){
                this.$toast('请先选择要操作的设备');
                return false;
            }
            if(this.isAisle){
                this.configItemIndex =i;
                this.isAisle = false;
                if(code=='1'){ //实时拍照、点一下、拍一张
                    this.$api.ITGS.subMedia({
                        devCode:this.result,
                        opType: '2',
                        switchStr: '1',
                        projectCode:this.projectCode
                    }).then(()=>{
                        this.$toast('实时拍照指令已下发');
                    })
                }else{ // 周期拍照
                    this.$api.ITGS.subMediaPeriod({
                        devCode:this.result,
                        opType: '2',
                        period:code,
                        switchStr: this.currentDevIsOpen ? '0' : '1',
                        projectCode:this.projectCode
                    }).then(()=>{
                        this.$toast(`${this.currentDevIsOpen ? '关闭' : '打开'}周期拍照指令已下发`);
                    })
                }
                const timeLimit = setTimeout(()=>{
                    this.isAisle = true;
                    clearTimeout(timeLimit)
                },5000)
            }else{
                this.$toast('操作频率过快，请在5秒之后再试')
            }
        },
        goMedia(){
            this.$router.push({path:'/itgs/media'})
        }
    }
}
</script>
<style lang="scss" scoped>
.block{
    background-color: #fff;
    padding: 2%;
    border-radius: 10px;
    .handle{
        padding: 0 4% 4% 4%;
        font-size: 0.95rem;
        .box-container{min-height:4rem;max-height:9.8rem;overflow-x: hidden;overflow-y: scroll;}
        .van-col{margin-bottom: 6%;}
    }
    .info{
        color:#656565;
        text-align: center;
        .active{color: #0957ff;}
        .only:active{
            color: #0957ff;
        }
        .line-block{text-align: left;padding-left: 5%;}
        .van-col{margin-bottom: 8%;}
        .fa{font-size: 1.5rem;vertical-align:middle}
        .text{font-size: .85rem;padding-top: 4px;}
    }
    .hint{
        padding: 0 10px;
        font-size: .8rem;
        color: #8e8e8e;
        div{ padding-bottom: 10px;}
        span{
            color: #0957ff;
            padding: 0 5px;
        }
    }
}
</style>